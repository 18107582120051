// Download

#download_block
  background-color: $dark
  padding-bottom: 80px

.download
  display: flex
  flex-flow: row wrap
  justify-content: center
  align-items: center
  +v-sm
    flex-flow: column nowrap
  &__link
    width: 20%
    margin: 0 1vw
    +v-sm
      width: 50%
      margin: 2% auto
    +v-xs
      width: 80%
      margin: 0 auto 6%
  &__img
    width: 100%
    height: auto
    object-fit: cover

.share
  display: flex
  justify-content: center
  align-items: center
  &__title
    margin: 80px auto 40px
    color: $white
    font-size: 1.35em
    font-weight: 300
    +v-sm
      margin: 40px auto 25px
    +v-xs
      width: 70%
      font-size: 1.15em
      line-height: 1.5
  &__link
    margin: 40px 10px
    +v-sm
      margin: 40px 15px 0
  &__image
    width: 32px
    opacity: 1
    transition: all ease-out .3s
    &:hover
      opacity: 0.8
